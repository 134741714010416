/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */
import { TextField } from "@talent-garden/react-components";
import React, { useEffect, useRef } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { useOnClickOutside, useScript } from 'usehooks-ts';
import { truncate } from "../../../utils";
import styles from "./AddressInput.module.css";

export const AddressInput = ({
  onChange,
  value: valueFromExternal = "",
  ...props
}) => {
  const {
    // ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 100,
    callbackName: "initMap",
  });

  const scriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`
  );
  //   {
  //   requestOptions: {
  //     types: ['street_address'],
  //   },
  //   // debounce: 100,
  // });

  // ref modal
  const ref = useRef();
  // State for our modal
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => {
    clearSuggestions();
  });

  // const [address, setAddress] = useState(props.value);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (!valueFromExternal) {
      return;
    }
    setValue(valueFromExternal, false);
  }, [setValue, valueFromExternal]);

  const handleSelect = (suggestion) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    onChange(suggestion);
    clearSuggestions();
  };

  const renderSuggestions = () => {
    return data.map((suggestion) => {
      const {
        place_id: placeId,
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
      } = suggestion;

      return (
        <li key={placeId} onClick={handleSelect(suggestion)}>
          <strong>{mainText}</strong>
          <small>{truncate(secondaryText, 65)}</small>
        </li>
      );
    });
  };

  return (
    scriptStatus === "ready" && (
      <div ref={ref} className={styles.container}>
        <TextField
          {...props}
          onChange={(e) => {
            if (scriptStatus !== "ready") return;
            handleInput(e);
          }}
          value={value}
        />
        {scriptStatus === "ready" && status === "OK" && (
          <ul className={styles.dropdown}>{renderSuggestions()}</ul>
        )}
      </div>
    )
  );
};

