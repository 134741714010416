import React, { FC, ReactNode, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useDimensions } from '../../hooks';
import { Company, User } from '../../types/globals';
import Footer from './Footer';
// import Header from './Header';
import styles from './Layout.module.css';
import Meta from './Meta';
import Nav from './Nav';
import Sidebar from './Sidebar';


const MAX_WIDTH_LAYOUT = 1200;

type LayoutProps = {
  company: Company,
  titlePrefix?: string,
  titleSuffix?: string,
  children: ReactNode,
  companyId: string | number,
  footerChildren?: ReactNode,
  noFollow?: boolean,
  extraFooter?: ReactNode,
  organizations?: Company[],
}

const Layout: FC<LayoutProps> = ({
  company,
  titlePrefix = "",
  titleSuffix = "",
  children,
  companyId,
  footerChildren,
  noFollow = false,
  extraFooter,
  organizations,
}) => {
  const [sidebar, setSidebar] = useLocalStorage('sidebarOpen', 'true');
  const [userMe] = useLocalStorage('userMe', {});
  const [sessionOrganizations] = useLocalStorage('organizations', []);
  const [ref, { width }] = useDimensions();

  useEffect(() => {
    if (width <= MAX_WIDTH_LAYOUT) {
      setSidebar(String(false))
    }
  }, [setSidebar, width])

  return (
    <>
      <Meta
        title={`${titlePrefix}${company?.name}${titleSuffix}`}
        noFollow={noFollow}
      />
      <Nav
        userMe={userMe as User}
        organizations={organizations || sessionOrganizations as Company[]}
        selectedCompany={company}
      />
      <div className={styles.container} ref={ref}>
        <Sidebar
          open={sidebar === "true"}
          setSidebarOpen={setSidebar}
          companyId={companyId}
          company={company}
          showOpenButton={width > MAX_WIDTH_LAYOUT}
        />
        <div className={styles.page}>{children}</div>
      </div>
      {footerChildren}
      {extraFooter}
      <Footer />
    </>
  );
};

export default Layout;
