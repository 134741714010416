import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styles from './PieChart.module.css';

const getAnglePoint = (startAngle, endAngle, radius, x, y) => {
  let x1 = 0;
  let y1 = 0;
  let x2 = 0;
  let y2 = 0;

  x1 = x + radius * Math.cos((Math.PI * startAngle) / 180);
  y1 = y + radius * Math.sin((Math.PI * startAngle) / 180);
  x2 = x + radius * Math.cos((Math.PI * endAngle) / 180);
  y2 = y + radius * Math.sin((Math.PI * endAngle) / 180);

  return { x1, y1, x2, y2 };
};

const Slice = ({ ...props }) => {
  const [state, setState] = useState({ path: '' });

  const draw = useCallback((s) => {
    let actualStep = s;
    const p = props;
    const path = state.path || [];
    let a = null;
    let b = null;
    let c = null;
    let step = null;

    step = p.angle / (37.5 / 2);

    if (actualStep + step > p.angle) {
      actualStep = p.angle;
    }

    // Get angle points
    a = getAnglePoint(
      p.startAngle,
      p.startAngle + actualStep,
      p.radius,
      p.radius,
      p.radius
    );
    b = getAnglePoint(
      p.startAngle,
      p.startAngle + actualStep,
      p.radius - p.hole,
      p.radius,
      p.radius
    );

    path?.push(`M${a.x1},${a.y1}`);
    path?.push(
      `A${p.radius},${p.radius} 0 ${actualStep > 180 ? 1 : 0},1 ${a.x2},${a.y2}`
    );
    //   'A' +
    //     p.radius +
    //     ',' +
    //     p.radius +
    //     ' 0 ' +
    //     (s > 180 ? 1 : 0) +
    //     ',1 ' +
    //     a.x2 +
    //     ',' +
    //     a.y2

    path?.push(`L${b.x2},${b.y2}`);
    // 'L' + b.x2 + ',' + b.y2
    path?.push(
      `A${p.radius - p.hole},${p.radius - p.hole} 0 ${
        actualStep > 180 ? 1 : 0
      },0 ${b.x1},${b.y1}`
    );
    // 'A' +
    //   (p.radius - p.hole) +
    //   ',' +
    //   (p.radius - p.hole) +
    //   ' 0 ' +
    //   (s > 180 ? 1 : 0) +
    //   ',0 ' +
    //   b.x1 +
    //   ',' +
    //   b.y1

    // Close
    path?.push('Z');

    setState({ ...state, path: path.join(' ') });

    if (actualStep < p.angle) {
      setTimeout(() => {
        draw(actualStep + step);
      }, 16);
    } else if (p.showLabel) {
      c = getAnglePoint(
        p.startAngle,
        p.startAngle + p.angle / 2,
        p.radius / 2 + p.trueHole / 2,
        p.radius,
        p.radius
      );

      setState({
        ...state,
        x: c.x2,
        y: c.y2,
      });
    }
  }, []);

  const animate = () => {
    draw(0);
  };

  useMemo(() => {
    setState({ ...state, path: '' });
    animate();
  }, []);

  useEffect(() => {
    animate();
  }, []);

  return (
    <g overflow='hidden'>
      <path
        d={state.path}
        fill={props?.fill}
        stroke={props?.stroke}
        strokeWidth={props?.strokeWidth ? props?.strokeWidth : 3}
      />
      {props?.showLabel && props?.percentValue > 5 ? (
        <text x={state?.x} y={state?.y} fill='#fff' textAnchor='middle'>
          {props?.percent ? `${props?.percentValue}%` : props?.value}
        </text>
      ) : null}
    </g>
  );
};

const PieChart = ({ ...props }) => {
  const colors = props?.colors;
  const colorsLength = colors.length;
  const labels = props?.labels;
  const hole = props?.hole;
  const radius = props?.radius;
  const diameter = radius * 2;
  // self = this,
  // sum,
  // startAngle,
  // d = null;

  const sum = props?.data.reduce((carry, current) => {
    return carry + current;
  }, 0);
  let startAngle = 0;

  return (
    <svg
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
    >
      {props?.data
        ?.map((slice) => (slice !== 0 ? slice : 0.00001))
        ?.map((slice, sliceIndex) => {
          let angle = null;
          let nextAngle = null;
          let percent = null;

          nextAngle = startAngle;
          angle = (slice / sum) * 360;
          percent = (slice / sum) * 100;
          startAngle += angle;

          return (
            <Slice
              key={`${slice}_${angle}`}
              value={slice}
              percent={props?.percent}
              percentValue={percent.toFixed(1)}
              startAngle={nextAngle}
              angle={angle}
              radius={radius}
              hole={radius - hole}
              trueHole={hole}
              showLabel={labels}
              fill={colors[sliceIndex % colorsLength]}
              stroke={props?.stroke}
              strokeWidth={props?.strokeWidth}
            />
          );
        })}
      {props.innerContainer ? (
        <foreignObject x='40' y='40' width='80' height='80'>
          {/* <!--
      In the context of SVG embedded in an HTML document, the XHTML
      namespace could be omitted, but it is mandatory in the
      context of an SVG document
    --> */}
          <div
            xmlns='http://www.w3.org/1999/xhtml'
            className={styles.foreignObject}
          >
            {props.innerContainer}
          </div>
        </foreignObject>
      ) : (
        <image
          href='/images/active_user.png'
          height='80'
          width='80'
          x={40}
          y={40}
        />
      )}
    </svg>
  );
};

export default PieChart;
