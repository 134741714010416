import React from 'react';
import {
  Heading,
  Text,
  Button,
  Flex,
  Container,
} from '@talent-garden/react-components';
import Image from './Image';
import styles from './ExtraFooter.module.css';
import { ButtonKinds } from '../../types/globals';

const ExtraFooter = ({
  title,
  description,
  buttonText,
  imageSrc,
  backgroundColor = 'burgundy',
  buttonKind = 'primary',
  buttonLink = '#',
}) => {
  return (
    <Container className={styles.container} data-background={backgroundColor}>
      <Flex
        horizontalAlign='center'
        verticalAlign='center'
        className={styles.wrapper}
      // columnGap={10}
      // rowGap={200}
      >
        <Flex direction='column' rowGap={12} className={styles.textAndButton}>
          <Heading as='h1' className={styles.heading} variant>
            {title}
          </Heading>
          <Text size={18}>{description}</Text>
          <Flex className={styles.button} direction='row'>
            <Button
              as='a'
              href={buttonLink}
              dimension='regular'
              kind={buttonKind as ButtonKinds}
              target='_blank'
              className={styles.button}
            >
              {buttonText}
            </Button>
          </Flex>
        </Flex>
        <div>
          <Image
            src={imageSrc}
            alt={title}
            className={styles.image}
            objectFit='contain'
            layout='fill'
            objectPosition=' center '
          />
        </div>
      </Flex>
    </Container>
  );
};
export default ExtraFooter;
