import React from 'react';
import { Flex } from '@talent-garden/react-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './connectLogo.module.css';
import Image from './Image';

const ConnectLogo = ({ width, height }) => {
  const router = useRouter();
  const { id } = router.query;

  const url = id ? `/organizations/${id}` : '/';
  return (
    <Flex horizontalAlign='left' className={styles.logo}>
      <Link itemProp='url' href={url} target='_blank' rel='noreferrer' passHref>
        <Image src='/images/logo.png' alt='' width={width} height={height} />
      </Link>
    </Flex>
  );
};

ConnectLogo.defaultProps = {
  width: 142,
  height: 60,
};

export default ConnectLogo;
