import React, { FC } from 'react';
import { Text, Heading } from "@talent-garden/react-components";
import mainFeature from "./MainFeature.module.css";
import { Image } from "../commons";

type FeatureCardProps = {
  title: string,
  label: string,
  description: string,
  image: string,
}

const FeatureCard: FC<FeatureCardProps> = ({
  title,
  label,
  description,
  image,
}) => {
  return (
    <div className={mainFeature.FeatureCard}>
      {image && (
        <Image
          src={image}
          alt={title}
          className={mainFeature.image}
          objectFit="contain"
          layout="fill"
          objectPosition="center right"
        />
      )}
      <Text weight="bold" textAlign="center" className={mainFeature.cardLabel}>
        {label}
      </Text>
      <Heading textAlign="center" className={mainFeature.cardTitle} as="h3">
        {title}
      </Heading>
      <Text
        size={16}
        textAlign="center"
        className={mainFeature.cardDescription}
      >
        {description}
      </Text>
    </div>
  );
};

const MainFeature: FC = () => {
  return (
    <div className={mainFeature.MainFeature}>
      <div className={mainFeature.title}>
        <Heading as="h2" variant>
          Main features
        </Heading>
      </div>
      <section className={mainFeature.section}>
        <FeatureCard
          label="CREATE A TEAM"
          title={"Invite the members of\nyour team"}
          description=
          "They will receive information to login in our App, open campuses doors and reserve rooms and desks."
          image="/images/main-feauture-1.png"
        />

        <FeatureCard
          label="TRACK YOUR TEAM"
          title={"Keep an eye on your\nteam, everywhere."}
          description=
          "Monitor your team, their subscription status and access directly from your dashboard."
          image="/images/main-feauture-2.png"
        />

        <FeatureCard
          label="MANAGE YOUR PRODUCTS"
          title={"See what you purchased\nand manage it."}
          description=
          "Buy Talent Garden products on our Marketplace and manage them on Connect. It’s easy."
          image="/images/main-feauture-3.png"
        />

        <FeatureCard
          label="CHECK YOUR BILLS"
          title={"Your bills, always\naccessible"}
          description=
          "With Connect you can always track your bills, download them or make them accessible to your team."
          image="/images/main-feauture-4.png"
        />
      </section>
    </div>
  );
};

export default MainFeature;
