import React from "react"
import { Flex } from "@talent-garden/react-components";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styles from "./HalfDoughnut.module.css";

ChartJS.register(ArcElement, ChartDataLabels);

const HalfDoughnut = ({ dataset = [], colors }) => {
  const options = {
    circumference: 180,
    rotation: -90,
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.5,
    legend: {
      display: false,
      position: "right",
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const data = {
    datasets: [
      {
        data: dataset,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };

  return (
    <Flex className={styles.wrapper}>
      <Doughnut data={data} options={{ ...options, cutout: 35 }} />
    </Flex>
  );
};

export default HalfDoughnut;
