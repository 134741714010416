import React from 'react';
import { Button, Flex } from '@talent-garden/react-components';
import styles from '../../styles/LandingPage.module.css';
import { ConnectLogo } from '../commons';

type StickyHeaderProps = {
  isSticky: boolean
}

export const StickyHeader = React.forwardRef<HTMLDivElement, StickyHeaderProps>(({ isSticky = false }, ref) => {
  return (
    <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
      <Flex
        className={styles.headerSticky}
        data-sticky={isSticky}
        direction='row'
        horizontalAlign='space-between'
        verticalAlign='center'
        rowGap={12}
        columnGap={12}
        horizontalPadding={48}
        verticalPadding={12}
      >
        <ConnectLogo width={100} height={40} />

        <Flex direction='row' rowGap={12} columnGap={12}>
          <Button
            as='button'
            capitalize
            dimension='regular'
            kind='primary'
            className={styles.button}
            onClick={() => {
              window.location.replace('/api/auth/login');
            }}
          >
            LOGIN
          </Button>
        </Flex>
      </Flex>
    </div>
  );
});
