import React, { FC, useMemo } from 'react';
import {
  Container,
  Flex,
  Heading,
  Text,
} from "@talent-garden/react-components";
import { capitalize, formatDay } from "../../utils";
import style from "./StatsNewMember.module.css";
import { User } from '../../types/globals';
import { SkeletonLoader } from '../commons';
import { Table } from '../commons/Table';

type StatsNewMemberProps = {
  userData: User[]
}

const StatsNewMember: FC<StatsNewMemberProps> = ({ userData }) => {
  const tableUserData = useMemo(() => {
    if (!userData) {
      return [];
    }
    return userData?.map((user) => {
      const lastAccess =
        user?.created_at && `${formatDay(user?.created_at, "YYYY/MM/DD")}`;

      return {
        col0: (
          <div className={style.firstColDetails}>
            <Flex horizontalAlign="left" verticalAlign="center" direction="row">
              {user?.full_name}
            </Flex>
          </div>
        ),
        col1: (
          <Flex horizontalAlign="left" verticalAlign="center" direction="row">
            {user?.full_name}
          </Flex>
        ),
        col3: (
          <Flex horizontalAlign="left" verticalAlign="center" direction="row">
            <Text textAlign="start" weight={user?.isOwner ? "bold" : "normal"} size={14}>
              {capitalize(user?.role)}
            </Text>
          </Flex>
        ),
        col4: <Text textAlign="start">{lastAccess}</Text>,
      };
    });
  }, [userData]);

  const tableUserColumns = useMemo(
    () => [
      {
        // Header: 'Full name',
        accessor: "col0",
      },
      {
        // Header: 'Full name',
        accessor: "col1",
      },
      {
        // Header: 'Role',
        accessor: "col3",
      },
      {
        // Header: 'Last access',
        accessor: "col4",
      },
    ],
    []
  );

  return (
    <Container dimension="full">
      <Flex direction="column" rowGap={16} className={style.main}>
        <Heading as="h4">New members:</Heading>
        {tableUserData?.length ? (
          <Table
            columns={tableUserColumns}
            data={tableUserData}
            noHeader
            pagination
            hideFirstColumn
            paginationSize={4}
          />
        ) : (
          <SkeletonLoader
            width="100%"
            height={250}
            marginVertical={20}
            marginHorizontal={0}
          />
        )}
      </Flex>
    </Container>
  );
};

export default StatsNewMember;
