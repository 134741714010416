import React, { FC } from 'react';
import { Button, Text, Heading } from "@talent-garden/react-components";
import style from "./ContactUs.module.css";
import { Image } from '../commons';

export const ContactUs: FC = () => {
  return (
    <div className={style.contactUs}>
      <section className={style.section}>
        <div className={style.card}>
          <Text weight="bold" className={style.label}>
            VISIT OUR CAMPUSES
          </Text>
          <Heading as="h1" variant className={style.title}>
            Start working with your team in our campuses.
          </Heading>
          <Button
            dimension="large"
            kind="primary"
            className={style.button}
            as="a"
            href="https://helpme.talentgarden.com/?product_category=coworking"
            target="_blank"
          >
            CONTACT US
          </Button>
        </div>
      </section>
      <div className={style.image}>
        <Image
          src="/images/sidebar-manager.png"
          layout="fill"
        />
      </div>
    </div>
  );
};
