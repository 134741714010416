import { Button } from "@talent-garden/react-components";
import React, { FC, ReactNode } from 'react';
import styles from "./tab.module.css";

type TabProps = {
  content: ReactNode;
  selected: boolean;
  onClick: () => void;
}

const Tab: FC<TabProps> = ({ content, selected = false, onClick = () => true }) => {
  return (
    <Button
      dimension="regular"
      kind="secondary-dark"
      noBorder
      onClick={onClick}
      capitalize={false}
      className={styles.tab}
      data-active={selected}
    >
      {content}
    </Button>
  );
};

export default Tab;
