import React, { FC } from 'react';
import { Flex, Icon } from '@talent-garden/react-components';
import styles from './SearchBar.module.css';

const SearchBar: FC<{ searchedValue: (v: string) => void; value: string | null }> = ({ searchedValue, value }) => {

  return (
    <Flex
      direction='row'
      rowGap={12}
      columnGap={12}
      verticalAlign='center'
      className={styles.searchContainer}
    >
      <Icon name='search/16' className={styles.iconSearch} />
      <input
        className={styles.input}
        type='text'
        value={value}
        placeholder='Search by email'
        onChange={(e) => {
          e.preventDefault();
          // todo fix
          searchedValue(e.target.value);
        }}
      />
    </Flex>
  );
};

export default SearchBar;
