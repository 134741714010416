import React from 'react';
import { Flex, Text } from "@talent-garden/react-components";
import Link from "next/link";

import styles from "./TalentGardenApps.module.css";
import Image from './Image';

const SingleApp = ({ text, src, link = "#", active = false, newTab = false }) => {
  return (
    <Link href={link} passHref>
      <a href={link} target={newTab ? "_blank" : "_self"} rel="noreferrer">
        <Flex
          direction="column"
          className={styles.imageWrapper}
          data-active={active}
          verticalAlign="space-between"
          horizontalAlign="center"
          columnGap={8}
          rowGap={8}
        >
          <Image
            src={src}
            alt={text}
            width={72}
            height={72}
            className={styles.image}
          />
          <Text>{text}</Text>
        </Flex>
      </a>
    </Link>
  );
};

const TalentGardenApps = () => {
  return (
    <Flex
      direction="column"
      horizontalAlign="start"
      verticalAlign="center"
      className={styles.wrapper}
      columnGap={16}
      rowGap={16}
    >
      <Flex direction="row" columnGap={16} rowGap={16}>
        <SingleApp
          src="/images/connect.png"
          text="Connect"
          link="/organizations"
          active
        />
        <SingleApp
          src="/images/marketplace.png"
          text="Marketplace"
          newTab
          link="https://store.talentgarden.com/"
        />
      </Flex>
      <Flex direction="row" columnGap={16} rowGap={16}>
        <SingleApp
          src="/images/app.png"
          text="App"
          newTab
          link="https://talentgarden.app/"
        />
        {/*  <SingleApp
          src="/images/talents.png"
          text="Talents"
          newTab
          link="https://talentgarden.com/"
        /> */}
      </Flex>
    </Flex>
  );
};

export default TalentGardenApps;
