import {
  Button,
  Flex,
  Heading,
  Separator,
  Text,
  TextField,
} from "@talent-garden/react-components";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import style from "./UserManageContent.module.css";
import { UserRole, User } from '../../types/globals';
import ChooseRole from './ChooseRole';

type UserManageContentProps = {
  onPress?: (email: string, role: UserRole) => void;
  user?: User,
  ctaText: string,
  error?: string,
}

const UserManageContent: FC<UserManageContentProps> = ({
  onPress,
  user,
  ctaText,
  error,
}) => {
  const schema = yup
    .object({
      email: yup
        .string()
        .required("The email is a required field")
        .email("This doesn't look like an email"),
    })
    .required();

  const {
    register,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: { email: user?.email },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const defaults = {
      email: user?.email,
    };
    reset(defaults);
  }, [user, reset]);

  const [role, setRole] = useState(user?.role || null);
  const email = watch("email");

  return (
    <div className={style.container}>
      <TextField
        dimension="regular"
        type="email"
        label="Email address"
        placeholder="Add user"
        {...register("email")}
        errorMessage={errors?.email?.message as string}
        disabled={!!user?.email}
        className={style.input}
      />
      <Flex direction="column" columnGap={24} rowGap={24}>
        <Flex
          direction="row"
          columnGap={12}
          rowGap={12}
          className={style.roles}
        >
          <ChooseRole
            selected={role === "admin"}
            setRole={() => setRole("admin")}
            title={<Heading as="h5">Admin</Heading>}
            description={
              <Text size={14}>
                If you want them to{" "}
                <strong>purchase new products and assign them</strong> to team
                members.
              </Text>
            }
            image="/images/star.png"
          />
          <ChooseRole
            selected={role === "member"}
            setRole={() => setRole("member")}
            title={<Heading as="h5">Team member</Heading>}
            description={
              <Text size={14}>
                If you only want them to <strong>use the products</strong> that
                admins purchase.
              </Text>
            }
            image="/images/hand.png"
          />
        </Flex>
        <Flex
          direction="column"
          columnGap={12}
          rowGap={12}
          horizontalAlign="center"
        >
          {!!ctaText && (
            <>
              <Button
                capitalize
                dimension="large"
                kind="primary"
                noBorder
                onClick={(e) => {
                  e.preventDefault();
                  onPress(email, role);
                }}
                type="button"
                disabled={!!Object.keys(errors)?.length || !role || !email}
              >
                {ctaText}
              </Button>
              {error?.toString()}
            </>
          )}
          <Separator />
          <Text
            textAlign="center"
            className={style.disclaimer}
            as="p"
            size={14}
          >
            When you add a new members, they will receive an email invitation to
            join our Talent Garden app to use the products that will be assigned
            them.
          </Text>
        </Flex>
      </Flex>
    </div>
  );
};

export default UserManageContent;
