import React from 'react';
import { Flex, Icon, Text } from "@talent-garden/react-components";
import styles from "./Footer.module.css";
import Image from './Image';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.details}>
        <a target="_blank" href="https://talentgarden.org/en"
          rel="noreferrer"
          className={styles.logoLink}>
          <Image
            src="/images/logo_white.png"
            alt="Talent Garden logo"
            className={styles.logo}
            objectFit="contain"
            layout="fill"
            objectPosition="center"
          />
        </a>
        <Flex>
          <Text size={14} weight="bold">
            TALENT GARDEN S.P.A.
          </Text>
          <Text size={14}>
            Piazza Città di Lombardia n. 1 20124\nMilan, Italy
          </Text>
        </Flex>
        <div className={styles.socialShare}>
          <ul className="">
            <li>
              <a target="_blank"
                href="https://www.facebook.com/talentgarden"
                rel="noreferrer">
                <Icon fill="white" name="facebook/24" />
              </a>
            </li>
            <li>
              <a target="_blank"
                href="https://www.instagram.com/talentgarden/"
                rel="noreferrer">
                <Icon fill="white" name="instagram/24" />
              </a>
            </li>
            <li>
              <a target="_blank"
                href="https://www.linkedin.com/company/talent-garden"
                rel="noreferrer">
                <Icon fill="white" name="linkedin/24" />
              </a>
            </li>
            <li>
              <a target="_blank"
                href="https://twitter.com/TalentGardenen"
                rel="noreferrer">
                <Icon fill="white" name="twitter/24" />
              </a>
            </li>
            <li>
              <a target="_blank"
                href="https://www.youtube.com/c/TalentGardenOfficial"
                rel="noreferrer">
                <Icon fill="white" name="youtube/24" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Flex className={styles.disclaimer} direction="column">
        <span>
          {`©${new Date().getFullYear()} Talent Garden S.p.A. - Nominal Capital €492.354 - REA MI-2099746 - P.IVA/VAT IT03340710981 | `}
        </span>
        <Flex direction="row">
          <a target="_blank" className={styles.link} href="https://talentgarden.org/en/privacy-policy/" rel="noreferrer">
            &nbsp;Privacy Policy&nbsp;
          </a>
          -
          <a target="_blank" className={styles.link} href="https://talentgarden.org/en/cookie-policy/" rel="noreferrer">
            &nbsp;Cookie Policy
          </a>
        </Flex>
      </Flex>
    </div>
  );
};

export default Footer;
