import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Flex,
  Select,
  Separator,
  TextField
} from '@talent-garden/react-components';
import React, { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import countriesData from '../../public/assets/countries.json';
import { Company } from '../../types/globals';
import { AddressInput, PhoneInput } from '../commons';
import styles from './SettingsForm.module.css';

const schema = yup
  .object({
    name: yup.string().required('Company name is a required field'),
    linkedin_url: yup
      .string()
      .nullable()
      .url('The LinkedIn profile must be a valid URL'),
    // address: yup.string().required('Address is a required field'),
    // state: yup.string().required('Province is a required field'),
    // city: yup.string().required('City is a required field'),
    // postal_code: yup
    //   .string()
    //   .required('Zip Code is a required field')
    //   .max(10, 'Zip code is too long'),
    // vat: yup
    //   .string()
    //   .required('VAT code is a required field')
    //   .test(
    //     'validVAT',
    //     'It appears that the VAT number entered is not valid.',
    //     async (value) => {
    //       try {
    //         const { data } = await axios.get(
    //           `/api/validation/vat?value=${value}`
    //         );
    //         return data?.data?.valid;
    //       } catch (err) {
    //         return false;
    //       }
    //     }
    //   ),
    // country: yup.string().required('Country is a required field'),
    // fiscal_code: yup
    //   .string()
    //   .required('Fiscal code is a required field')
    //   .matches(
    //     /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i,
    //     'Wrong fiscal code'
    //   ),
    // pec_email: yup
    //   .string()
    //   .email('Invalid pec address')
    //   .required('PEC Address is a required field'),
    // code_sdi: yup
    //   .string()
    //   .max(7, 'Wrong SDI Code (too long)')
    //   .min(6, 'Wrong SDI Code (too short)'),
    // billing_email: yup
    //   .string()
    //   .required('The billing email is a required field')
    //   .email(`This doesn't look like an email`),
    // phone_number: yup
    //   .string()
    //   .required(
    //     // 'The phone number is a required field, we would not use it for marketing purpose'
    //     'The phone number is a required field'
    //   )
    //   // validazione numero => [solo digit o -] min 8 max 12
    //   .matches(/^[\d-]{8,12}$/gm, 'Invalid phone number'),
  })
  .required();

type SettingsFormProps = {
  company: Company;
  onSubmit: (payload: any, event: any) => void;
  onError: (error: any, event: any) => void;
}

export const SettingsForm: FC<SettingsFormProps> = ({ company, onSubmit, onError }) => {
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: company?.name,
      linkedin_url: company?.linkedin_url,
      address: company?.address,
      country: company?.country,
      state: company?.state,
      city: company?.city,
      postal_code: company?.postal_code,
      vat: company?.vat,
      billing_email: company?.billing_email,
      phone_prefix: company?.phone_prefix,
      phone_number: company?.phone_number,
      fiscal_code: company?.fiscal_code,
      code_sdi: company?.code_sdi,
      pec_email: company?.pec_email,
    },
    resolver: yupResolver(schema),
  });

  const renderCountriesOptions = useMemo(() => {
    const list = countriesData.map((country) => {
      return (
        <option key={`${country?.code}_${country?.name}`
        } value={country?.name} >
          {country?.name}
        </option>
      );
    });
    return [...list];
  }, []);

  useEffect(() => {
    const defaults = {
      name: company?.name,
      linkedin_url: company?.linkedin_url,
      address: company?.address,
      country: company?.country,
      state: company?.state,
      city: company?.city,
      postal_code: company?.postal_code,
      vat: company?.vat,
      billing_email: company?.billing_email,
      phone_prefix: company?.phone_prefix,
      phone_number: company?.phone_number,
      fiscal_code: company?.fiscal_code,
      code_sdi: company?.code_sdi,
      pec_email: company?.pec_email,
    };
    reset(defaults);
  }, [company, reset]);

  const country = watch('country');
  const address = watch('address');

  return (
    <div className={styles.form} >
      <TextField
        dimension='regular'
        label='Company Name'
        placeholder='My Company Ltd'
        {...register('name')}
        errorMessage={errors?.name?.message as string}
      />
      <TextField
        dimension='regular'
        label='Linkedin Profile (optional)'
        placeholder='https://www.linkedin.com/company/thebestcompanyintheworld'
        {...register('linkedin_url')}
        errorMessage={errors?.linkedin_url?.message as string}
      />
      <AddressInput
        dimension='regular'
        errorMessage={errors?.address?.message}
        label='Address'
        placeholder='Address'
        {...register('address')}
        onChange={(addr) => {
          setValue('address', addr?.structured_formatting?.main_text, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }}
        value={address}
        disabled
      />
      <Select
        dimension='regular'
        kind='single'
        label='Country'
        // onChange={()=>{

        // }}
        {...register('country', { required: true })}
        defaultValue='Pick an option'
        disabled
      >
        {renderCountriesOptions}
      </Select>
      < TextField
        dimension='regular'
        errorMessage={errors?.state?.message as string}
        label='Province'
        placeholder='Province'
        {...register('state')}
        disabled
      />
      <Flex direction='row' >
        <TextField
          dimension='regular'
          errorMessage={errors?.city?.message as string}
          label='City'
          placeholder='City'
          {...register('city')}
          disabled
        />
        <TextField
          dimension='regular'
          errorMessage={errors?.postal_code?.message as string}
          label='ZIP code'
          placeholder='ZIP code'
          {...register('postal_code')}
          disabled
        />
      </Flex>
      < TextField
        dimension='regular'
        errorMessage={errors?.vat?.message as string}
        label='VAT code'
        placeholder='ITXXXXXXXXX'
        {...register('vat')}
        disabled
      />
      <TextField
        dimension='regular'
        errorMessage={errors?.billing_email?.message as string}
        label='Billing Email'
        placeholder='administration@yourcompany.com'
        {...register('billing_email')}
        disabled
      />

      <PhoneInput
        register={register}
        phoneNumberError={errors?.phone_number?.message as string}
      // disabled
      />
      {country?.toLowerCase() === 'italy' && (
        <>
          <TextField
            dimension='regular'
            errorMessage={errors?.fiscal_code?.message as string}
            label='Fiscal code'
            placeholder='Fiscal code'
            {...register('fiscal_code')}
            disabled
          />
          <TextField
            dimension='regular'
            errorMessage={errors?.code_sdi?.message as string}
            label='SDI Code'
            placeholder='SDI Code'
            {...register('code_sdi')}
            disabled
          />
          <TextField
            dimension='regular'
            errorMessage={errors?.pec_email?.message as string}
            label='PEC Address'
            placeholder='PEC'
            {...register('pec_email')}
            disabled
          />
        </>
      )}
      <Separator />
      <Flex
        direction='row'
        columnGap={24}
        horizontalAlign='center'
        className={styles.buttons}
      >
        {/* <Button
          capitalize
          dimension="regular"
          kind="secondary"
          onClick={function noRefCheck() {}}
        >
          Cancel
        </Button> */}
        <Button
          capitalize
          dimension='regular'
          kind='primary'
          onClick={() => handleSubmit(onSubmit, onError)()}
          disabled={
            !Object.keys(dirtyFields)?.length || !!Object.keys(errors)?.length
          }
        >
          Save Changes
        </Button>
      </Flex>
    </div>
  );
};
