import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const WEEKDAYS_SHORT = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const getWeekDays = (weekStart) => {
  const days = [];
  for (let i = 0; i < 7; i += 1) {
    const day = moment(weekStart).add(i, 'days');
    if (!day?.isAfter(moment().endOf('week'))) {
      days.push(day.toDate());
    }
  }
  return days;
};

const getWeekRange = (date) => {
  if (moment(date)?.isAfter(moment().endOf('week'))) {
    return null;
  }
  return {
    from: !!date && moment(date).startOf('week').toDate(),
    to: !!date && moment(date).endOf('week').toDate(),
  };
};

type WeekPickerProps = {
  onChangeDays: (from: any, to: any) => void,
  defaultSelectedDay: string | null,
}

const WeekPicker: FC<WeekPickerProps> = ({
  onChangeDays,
  defaultSelectedDay = null,
}) => {
  const [selectedDays, setSelectedDays] = useState(null);
  const [defaultDays, setDefaultDays] = useState(null);
  const [hoverRange, setHoverRange] = useState(undefined);

  const handleDayChange = (date: Date | string, showDefaultDays = false) => {
    if (moment(date)?.isAfter(moment().endOf('week'))) {
      return null;
    }
    if (showDefaultDays) {
      return setDefaultDays(getWeekDays(getWeekRange(date)?.from));
    }
    return setSelectedDays(getWeekDays(getWeekRange(date)?.from));
  };

  const handleDayEnter = (date: Date) => {
    if (moment(date)?.isAfter(moment().endOf('week'))) {
      return;
    }
    setHoverRange(getWeekRange(date));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const handleWeekClick = (_: number,
    days: Date[]) => {
    setSelectedDays(days);
  };

  useEffect(() => {
    if (!selectedDays?.length) {
      return;
    }
    // console.log(
    //   'SELECTED DAYS',
    //   moment(selectedDays?.[0])?.format(),
    //   moment(selectedDays[selectedDays?.length - 1])
    //     ?.endOf('day')
    //     ?.format()
    // );
    onChangeDays(
      moment(selectedDays?.[0]),
      // eslint-disable-next-line no-unsafe-optional-chaining
      moment(selectedDays[selectedDays?.length ? selectedDays?.length - 1 : 0])
    );
  }, [selectedDays]);

  useEffect(() => {
    if (!defaultSelectedDay) {
      return;
    }
    /* TODO check se funziona */
    handleDayChange(moment(defaultSelectedDay).toLocaleString(), true);
  }, [defaultSelectedDay]);

  const daysAreSelected = selectedDays?.length > 0 || defaultDays?.length > 0;

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays?.[0] || defaultDays?.[0],
      to: selectedDays?.[6] || defaultDays?.[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart:
      daysAreSelected && (selectedDays?.[0] || defaultDays?.[0]),
    selectedRangeEnd:
      daysAreSelected && (selectedDays?.[6] || defaultDays?.[6]),
  };

  return (
    <DayPicker
      selectedDays={selectedDays || defaultDays}
      // showWeekNumbers
      showOutsideDays
      modifiers={modifiers}
      onDayClick={(day) => handleDayChange(day)}
      onDayMouseEnter={handleDayEnter}
      onDayMouseLeave={handleDayLeave}
      onWeekClick={handleWeekClick}
      weekdaysShort={WEEKDAYS_SHORT}
      disabledDays={{ after: moment()?.toDate() }}
      fromMonth={moment('2021-09-01')?.toDate()}
      toMonth={moment()?.toDate()}
    />
  );
};

export default WeekPicker;
