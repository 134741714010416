import React, { FC } from 'react';
import { Button, Dialog } from "@talent-garden/react-components";
import styles from "./AddUserDialog.module.css";
import { UserRole } from '../../types/globals';
import UserManageContent from './UserManageContent';


type AddUserDialogProps = {
  onUserAdd?: (email: string, role: UserRole) => void;
  setOpen: (visible: boolean) => void;
  open: boolean,
}

const AddUserDialog: FC<AddUserDialogProps> = ({
  onUserAdd,
  setOpen,
  open = false,
}) => {
  return (
    <Dialog
      open={open}
      withOverlay
      activator={
        <Button
          capitalize
          className={styles.addMember}
          dimension="regular"
          icon="plus/24"
          kind="secondary"
          noBorder={false}
          onClick={() => setOpen(true)}
          type="button"
        >
          Add member
        </Button>
      }
      onOpenChange={(visible) => {
        setOpen(visible);
      }}
      title="Add new member"
    >
      <UserManageContent
        onPress={(email, role) => {
          onUserAdd(email, role);
        }}
        ctaText="ADD NEW MEMBER"
      />
    </Dialog>
  );
};

export default AddUserDialog;
