import React, { FC } from 'react';
import {
  Heading,
  Text,
  Flex,
  Button,
  Container,
} from '@talent-garden/react-components';
import styles from './MarketBanner.module.css';
import Image from './Image';

type MarketBannerProps = {
  description: string,
  imageSrc: string,
  title: string,
  buttonText: string,
  href: string,
  backgroundColor: "peach" | "burgundy" | "grey",
}

const MarketBanner: FC<MarketBannerProps> = ({
  description,
  imageSrc,
  title,
  buttonText,
  href = '#',
  backgroundColor = 'burgundy',
}) => {
  return (
    <Container
      data-background={backgroundColor}
      className={styles.parent}
    >
      <Flex
        direction='row'
        verticalAlign='center'
        columnGap={24}
        className={styles.main}
      >
        <Image
          src={imageSrc}
          alt={title}
          className={styles.image}
          objectFit='contain'
          layout='fill'
          objectPosition='center'
        />
        <Flex direction='row' rowGap={32} columnGap={24}>
          <Flex direction='column' rowGap={8} columnGap={8}>
            <Heading as='h4'>{title}</Heading>
            <Text>{description}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction='column' rowGap={200} className={styles.flexButton}>
        <Button
          href={href}
          dimension='large'
          kind='primary-dark'
          target='_blank'
          as='a'
        >
          {buttonText}
        </Button>
      </Flex>
    </Container>
  );
};
export default MarketBanner;
