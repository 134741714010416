import React, { FC } from 'react';
import { Button, Text, Heading } from '@talent-garden/react-components';
import style from './ContactSupport.module.css';
import { Image } from '../commons';

const ContactSupport: FC = () => {
  return (
    <div className={style.contactSupport}>
      <section className={style.section}>
        <div className={style.card}>
          <Text weight='bold' className={style.label}>
            SUPPORT
          </Text>
          <Heading as='h1' className={style.title}>
            Ask for help to our staff. We will be happy to aid you.
          </Heading>
          <Button
            as='a'
            href='https://helpme.talentgarden.com/'
            dimension='large'
            kind='primary-dark'
          >
            ASK FOR HELP
          </Button>
        </div>
        <div className={style.card}>
          <Image src='/images/support-mockup.png' width={298} height={315} />
        </div>
      </section>
    </div>
  );
};

export default ContactSupport;
