import React, { FC } from 'react';
import styles from './Skeleton.module.css';

type SkeletonLoaderProps = {
  width: string | number | null,
  height: string | number | null,
  marginVertical: number,
  marginHorizontal: number,
};

const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  width,
  height,
  marginVertical,
  marginHorizontal,
}) => {
  if (!width && !height) {
    return null;
  }
  return (
    <div
      className={styles.loading}
      style={{
        width,
        height,
        marginTop: marginVertical,
        marginBottom: marginVertical,
        marginRight: marginHorizontal,
        marginLeft: marginHorizontal,
      }}
    />
  );
};

export default SkeletonLoader;
