import React, { FC, useState } from 'react';
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Heading,
  Icon,
  Separator,
  Text,
} from '@talent-garden/react-components';
import Link from 'next/link';
import navStyles from './Nav.module.css';
import { Company, User } from '../../types/globals';
import ConnectLogo from './ConnectLogo';
import TalentGardenApps from './TalentGardenApps';

type NavProps = {
  userMe: User,
  organizations: Company[],
  selectedCompany: Company
}

const Nav: FC<NavProps> = ({ userMe, organizations, selectedCompany }) => {
  const [showProducts, setShowProducts] = useState<boolean>(false);
  const [showUserPicker, setShowUserPicker] = useState<boolean>(false);

  return (
    <Flex direction='column'>
      <nav className={navStyles.nav}>
        <ConnectLogo width={104} height={44} />
        <ul>
          <Dropdown
            dimension='small'
            activator={
              <Button
                capitalize
                dimension='regular'
                icon='menu-grid/24'
                kind='secondary-light'
                noBorder
              />
            }
            open={showProducts}
            onOpenChange={(v) => setShowProducts(v)}
          >
            <TalentGardenApps />
          </Dropdown>
          <Dropdown
            dimension='small'
            className={navStyles.container}
            open={showUserPicker}
            onOpenChange={(v) => setShowUserPicker(v)}
            activator={
              <Button
                capitalize
                dimension='regular'
                fullWidth
                kind='secondary-dark'
                className={navStyles.activator}
              >
                <Avatar
                  backgroundColor='dark'
                  fallback={selectedCompany?.name}
                  size='regular'
                  src=''
                />
                <Icon fill='' name='dots/24' />
              </Button>
            }
            title={userMe?.full_name || ''}
          >
            <Flex
              direction='column'
              horizontalAlign='space-between'
              verticalAlign='center'
              columnGap={8}
              rowGap={8}
              className={navStyles.companies}
            >
              <Text size={14} className={navStyles.email}>
                {organizations?.length > 0 ? 'My teams' : 'My team'}
              </Text>
              {organizations?.map((company) => (
                <Link
                  passHref
                  href={`/organizations/${company?.id}`}
                  rel='noreferrer'
                  key={company?.id}
                >
                  <Flex
                    direction='row'
                    horizontalAlign='space-between'
                    verticalAlign='center'
                    className={navStyles.company}
                    data-selected={company?.id === selectedCompany?.id}
                  >
                    <Flex
                      direction='row'
                      horizontalAlign='left'
                      verticalAlign='center'
                      data-selected={company?.id === selectedCompany?.id}
                      columnGap={8}
                      rowGap={8}
                    >
                      <Avatar
                        backgroundColor='dark'
                        fallback={company?.name}
                        size='regular'
                        src=''
                      />
                      <Text size={14}>{company?.name}</Text>
                    </Flex>
                    <Link
                      passHref
                      href={`/organizations/${company?.id}/settings/`}
                      rel='noreferrer'
                    >
                      <Icon name='edit/16' className={navStyles.iconSetting} />
                    </Link>
                  </Flex>
                </Link>
              ))}

              <Button
                as='button'
                capitalize
                dimension='large'
                kind='secondary-dark'
                target='_blank'
                className={navStyles.logout}
                onClick={() => {
                  sessionStorage.clear();
                  localStorage.clear();
                  window.location.replace('/api/auth/logout');
                }}
              >
                logout
              </Button>
            </Flex>
          </Dropdown>
        </ul>
      </nav>
      <Separator />
    </Flex >
  );
};

Nav.defaultProps = {};

export default Nav;
