
import React, { FC } from 'react';
import { Flex, Icon, Text } from "@talent-garden/react-components";
import styles from "./Pagination.module.css";

type PaginationProps = {
  pages: number;
  selectedPage: number;
  setSelectedPage: (page) => void;
  nextPage: () => void;
  previousPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean
}

const Pagination: FC<PaginationProps> = ({
  pages,
  selectedPage = 1,
  setSelectedPage,
  nextPage = () => true,
  canNextPage,
  previousPage = () => true,
  canPreviousPage,
}) => {
  return (
    <Flex
      direction="row"
      verticalAlign="center"
      horizontalAlign="center"
      className={styles.pagination}
      rowGap={16}
      columnGap={16}
    >
      <Icon
        name="arrow-simple-left/16"
        onClick={previousPage}
        data-disabled={!canPreviousPage}
        fill={canPreviousPage ? "black" : "grey"}
        className={styles.icon}
      />
      {new Array(pages)
        .fill(null)
        .map((_, i) => i + 1)
        ?.map((elem) => {
          return (
            <Text
              weight="bold"
              className={styles.page}
              onClick={() => setSelectedPage(elem - 1)}
              data-selected={elem - 1 === selectedPage}
              key={elem}
              size={14}
            >
              {elem}
            </Text>
          );
        })}

      <Icon
        name="arrow-simple-right/16"
        onClick={nextPage}
        data-disabled={!canNextPage}
        fill={canNextPage ? "black" : "grey"}
        className={styles.icon}
      />
    </Flex>
  );
};

export default Pagination;
