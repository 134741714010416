import Img from 'next/image';
import React, { FC } from 'react';

type ImageProps = {
  className?: string,
  src: string | null;
  alt?: string | null;
  width?: number;
  height?: number;
  objectFit?: "contain";
  layout?: "fill";
  objectPosition?: string | null;
};

const Image: FC<ImageProps> = ({ className, ...props }) => {
  // const sanityIoImageLoader = ({ src, width, quality }) => {
  //   return `https://cdn.sanity.io${src}?w=${width || 200}&q=${quality || 75}`;
  // };

  if (!props?.src) return null;

  return (
    <div className={className}>
      <Img
        /* loader={sanityIoImageLoader} placeholder="blur" */
        priority
        {...props}
      />
    </div>
  );
};

export default Image;
