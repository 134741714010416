import React, { FC, useMemo } from 'react';
import {
  Text,
  Heading,
  Flex,
  Separator,
  Icon,
} from "@talent-garden/react-components";
import PieChart from "../charts/PieChart";
import style from "./StatsActiveMember.module.css";
import { SkeletonLoader } from '../commons';

type StatsActiveMemberProps = {
  activeUsers: number,
  totalUsers: number,
  uniqueUsersPreviousWeek: number
}

const StatsActiveMember: FC<StatsActiveMemberProps> = ({
  activeUsers,
  totalUsers,
  uniqueUsersPreviousWeek = 0,
}) => {
  const data = useMemo(() => {
    return [
      totalUsers - activeUsers,
      activeUsers !== 0 ? activeUsers : 0.00001,
    ];
  }, [totalUsers, activeUsers]);

  const diffUsers = useMemo(() => {
    return activeUsers - uniqueUsersPreviousWeek;
  }, [activeUsers, uniqueUsersPreviousWeek]);

  return (
    <Flex
      direction="column"
      verticalAlign="center"
      horizontalAlign="center"
      rowGap={32}
      className={style.main}
    >
      {totalUsers >= 0 && activeUsers >= 0 ? (
        <PieChart
          data={data}
          radius={80}
          hole={65}
          colors={["#F5F5F5", "#723349"]}
          strokeWidth={1}
        />
      ) : (
        <SkeletonLoader
          width={160}
          height={160}
          marginVertical={20}
          marginHorizontal={0}
        />
      )}

      <Flex rowGap={16} horizontalAlign="center">
        <Flex>
          <Heading textAlign="center" as="h2" variant>
            {/* TODO Check se funziona */}
            {activeUsers > 9 ? activeUsers : `0${activeUsers}`}/{totalUsers}
          </Heading>
          <Text textAlign="center" size={16}>
            Active team members
          </Text>
        </Flex>
        <Separator />
        <Flex
          horizontalAlign="center"
          verticalAlign="center"
          direction="row"
          columnGap={8}
          rowGap={8}
        >
          <Icon
            fill={diffUsers >= 0 ? "#73d17d" : "#e16161"}
            name={diffUsers >= 0 ? "arrow-increase/24" : "arrow-decrease/24"}
          />

          <Text size={14}>
            {`${diffUsers >= 0 ? "+" : ""}${diffUsers} compared to last week`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default StatsActiveMember;
