import { Flex } from "@talent-garden/react-components";
import {
  CategoryScale, Chart, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title,
  Tooltip
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import styles from "./LineChart.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Filler
);

const LineChart = ({ dataset = [], labels = [] }) => {
  const chartRef = useRef<Chart<"line", any[], unknown> | null>();
  let gradientLine = null;
  if (chartRef?.current) {
    // const myChartRef = chartRef.current.getContext("2d");

    const x1 = 150;
    const y1 = 150;
    const length = 100;

    let x2 = 0;
    let y2 = 0;
    let angle = 0;

    angle = (+90 / 180) * Math.PI;

    // calculate gradient line based on angle
    x2 = x1 + Math.cos(angle) * length;
    y2 = y1 + Math.sin(angle) * length;

    gradientLine = chartRef?.current?.ctx?.createLinearGradient(x1, y1, x2, y2);

    gradientLine.addColorStop(0, "rgba(255, 205, 168, 0.4)");
    gradientLine.addColorStop(1, "rgba(255, 205, 168, 0)");
  }

  const options = {
    responsive: true,
    // aspectRatio: 1.8,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: dataset?.map((_, index) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          if (index === 0 || (dataset?.length && index === dataset?.length - 1)) {
            return false;
          }
          return true;
        }),
        color: "rgba(28, 30, 30, 1)",
        anchor: "end",
        align: "end",
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          color: "rgba(28, 30, 30, 1)",
          font: (context) => {
            const { width } = context.chart;
            const size = Math.round(width / 48);

            return {
              weight: "bold",
              size,
            };
          },
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: Math.max(...dataset) + 2,
        ticks: {
          count: 4,
          display: false,
          max: 10, // maximum value
        },
        grid: {
          borderDash: [3, 2],
        },
      },
    },
    elements: {
      point: {
        borderWidth: dataset?.map((v) => {
          if (v === 0) {
            return 0;
          }
          return 6;
        }),
        hoverBorderWidth: dataset?.map((v) => {
          if (v === 0) {
            return 0;
          }
          return 4;
        }),
        borderColor: () => {
          return "#FFCDA8";
        },
        backgroundColor: "#EE7660",
      },
      line: {
        borderColor: "#EE7660",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "none",
        data: dataset,
        tension: 0.4,
        pointRadius: dataset?.map((v, index) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          if (index === 0 || (dataset?.length && index === dataset?.length - 1)) {
            return 0;
          }
          if (v === 0) {
            return 4;
          }
          return 6;
        }),
        backgroundColor: gradientLine,
        fill: true,
        pointBackgroundColor: "#EE7660",
      },
    ],
  };

  return (
    <Flex className={styles.wrapper}>
      <Line
        data={data}
        options={options as any}
        width={950}
        height={300}
        ref={chartRef}
      />
    </Flex>
  );
};

export default LineChart;
