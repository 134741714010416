import React from 'react';
import {
  Flex,
  Heading,
  Text,
  Separator,
  Icon,
} from "@talent-garden/react-components";
import styles from "./CampusAccessToday.module.css";

const CampusAccessToday = ({
  description = "",
  title = "",
  count = 0,
  countText = "",
  diff = 0,
}) => {
  return (
    <Flex
      direction="column"
      rowGap={12}
      horizontalAlign="center"
      verticalAlign="space-between"
      className={styles.wrapper}
    >
      <Heading as="h5" className={styles.entrances}>
        {title}
      </Heading>
      <Flex direction="column" rowGap={12} horizontalAlign="center">
        <Heading as="h1" variant>
          {count}
        </Heading>
        <Text>{countText}</Text>
      </Flex>
      <Flex direction="column" rowGap={12} horizontalAlign="center">
        <Separator className={styles.separator} />
        <Flex
          horizontalAlign="center"
          verticalAlign="center"
          direction="row"
          columnGap={8}
          rowGap={8}
        >
          <Icon
            fill={diff >= 0 ? "#73d17d" : "#e16161"}
            name={diff >= 0 ? "arrow-increase/24" : "arrow-decrease/24"}
          />
          <Text size={14}>{description}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CampusAccessToday;
