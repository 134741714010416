import React from 'react';
import { Flex, Select, TextField } from '@talent-garden/react-components';
import styles from './PhoneInput.module.css';
import countries from '../../../public/assets/countries.json';

export const PhoneInput = ({ register, phoneNumberError = '' }) => {
  const renderCountriesDialOptions = () => {
    return countries.map((country) => {
      return (
        <option
          key={`${country?.dial_code}_${country?.name}`}
          value={country?.dial_code}
        >
          {country?.dial_code} {country?.name}
        </option>
      );
    });
  };

  return (
    <Flex direction='row' className={styles.phone}>
      <Select
        dimension='regular'
        kind='single'
        {...register('phone_prefix')}
        label='Phone number'
        defaultValue='+xx'
        disabled
        className={styles.prefix}
      >
        {renderCountriesDialOptions()}
      </Select>
      <TextField
        dimension='regular'
        errorMessage={phoneNumberError}
        placeholder='Phone number'
        // label={'Phone number'}
        {...register('phone_number')}
        type='phone'
        disabled
      />
    </Flex>
  );
};
