import {
  Container,
  Flex,
  Icon,
  Separator,
  Text,
} from '@talent-garden/react-components';
import React, { useEffect, useState, FC, ReactNode, useMemo } from 'react';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { toggle } from '../../../utils';
import { Pagination } from '../../people';
import SearchBar from '../SearchBar';
import styles from './Table.module.css';

// Define a default UI for filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((v: any) => {
    setGlobalFilter(v || undefined);
  }, 200);

  // useEffect(() => {
  //   setValue(globalFilter);
  // }, [globalFilter]);

  return (
    <>
      <Separator />
      <SearchBar
        searchedValue={(v) => {
          setValue(v);
          onChange(v);
        }}
        value={value}
      />
      <Separator />
    </>
  );
};

const EmptyState = ({ children }) => {
  return (
    <Flex
      direction='column'
      rowGap={16}
      columnGap={16}
      verticalAlign='center'
      horizontalAlign='center'
      className={styles.emptyState}
    >
      {children}
    </Flex>
  );
};

type TableProps = {
  columns: any[],
  data: any[],
  sortBy?: boolean,
  withSearch?: boolean,
  noHeader?: boolean,
  pagination?: boolean,
  paginationSize?: number,
  hideFirstColumn?: boolean,
  pageCount?: number,
  fetchData?: (values: any) => void,
  manualPagination?: boolean,
  resetPage?: boolean,
  emptyStateComponent?: ReactNode,
};

export const Table: FC<TableProps> = ({
  columns,
  data = [],
  sortBy = false,
  withSearch = false,
  noHeader = false,
  pagination = false,
  paginationSize = 4,
  hideFirstColumn = false,
  pageCount: controlledPageCount = 1,
  fetchData,
  manualPagination = false,
  resetPage = false,
  emptyStateComponent = () => true,
}) => {
  const [controlledPageIndex, setControlledPage] = useState(0);
  const [searchedValue, setSearchedValue] = useState("");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
    pageCount,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: pagination ? paginationSize : data?.length,
        globalFilter: '',
      },
      manualPagination,
      pageCount: controlledPageCount,
      useControlledState: (currentState: any) => {
        return useMemo(
          () => ({
            ...currentState,
            pageIndex: controlledPageIndex,
            globalFilter: searchedValue
          }),
          [state, controlledPageIndex, searchedValue]
        );
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (pagination) {
      return setPageSize(paginationSize);
    }
    return setPageSize(data?.length);
  }, [setPageSize, data, pagination, paginationSize]);

  useEffect(() => {
    if (!fetchData) {
      return;
    }
    fetchData({
      pageIndex: state.pageIndex + 1 /*  pageSize: state.pageSize */,
    });
  }, [
    fetchData,
    state.pageIndex,
    /*  state.pageSize */
  ]);

  useEffect(() => {
    if (!resetPage) {
      return;
    }
    setControlledPage(0);
  }, [resetPage]);

  // useEffect(() => {
  //   setGlobalFilter(searchedValue);
  // }, [setGlobalFilter, searchedValue]);

  const [openedRow, setOpenedRow] = useState([]);

  const onClickRow = (rowIndex) => {
    const localRows = toggle(openedRow, rowIndex, (item) => item);
    setOpenedRow(localRows);
  };

  // useEffect(() => {
  //   if (!onSearch || !state.globalFilter) {
  //     return;
  //   }
  //   onSearch(state.globalFilter);
  // }, [onSearch, state.globalFilter]);

  return (
    // apply the table props
    <Container className={styles.table}>
      {/* <Styles> */}
      <Flex direction='column' rowGap={16} columnGap={16}>
        {withSearch && (
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setSearchedValue}
          />
        )}
        {page?.length > 0 ? (
          <>
            <Flex className={styles.tableContainer}>
              <table {...getTableProps()}>
                {!noHeader && (
                  <thead>
                    {
                      // Loop over the header rows
                      headerGroups.map((headerGroup) => {
                        return (
                          // Apply the header row props
                          <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className={styles.tr}
                            key={headerGroup?.id}
                            data-header
                          >
                            {
                              // Loop over the headers in each row
                              headerGroup.headers.map(
                                (column: any, index: number) => {
                                  const isFirst = index === 0;
                                  return (
                                    // Apply the header cell props
                                    <th
                                      key={column?.Header}
                                      data-first={isFirst}
                                      data-hide={isFirst && hideFirstColumn}
                                      className={styles.th}
                                      {...column.getHeaderProps(
                                        sortBy
                                          ? column.getSortByToggleProps()
                                          : () => true
                                      )}
                                    >
                                      <Flex
                                        direction='row'
                                        verticalAlign='center'
                                      >
                                        {
                                          // Render the header
                                          column.render('Header')
                                        }
                                        {column.isSorted ? (
                                          <Icon
                                            className={styles.sortIcon}
                                            name={
                                              column.isSortedDesc
                                                ? 'arrow-up/16'
                                                : 'arrow-down/16'
                                            }
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </Flex>
                                    </th>
                                  );
                                }
                              )
                            }
                          </tr>
                        )
                      })
                    }
                  </thead>
                )}
                {/* Apply the table body props */}

                <tbody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    page.map((row, rowIndex) => {
                      // Prepare the row for display
                      const open =
                        openedRow?.findIndex((elem) => {
                          return elem === rowIndex;
                        }) > -1;

                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={styles.tr}
                          key={row?.id}
                          data-open={open}
                        >
                          {
                            // Loop over the rows cells
                            row.cells.map((cell, cellIndex) => {
                              if (!cell?.value) {
                                return null;
                              }
                              const isFirst = cellIndex === 0;

                              // Apply the cell props
                              return (
                                <td
                                  key={`${cell?.row.id}_${cell?.column?.id}`}
                                  {...cell.getCellProps()}
                                  data-first={isFirst}
                                  data-hide={isFirst && hideFirstColumn}
                                  className={styles.td}
                                  data-open={open}
                                >
                                  <Flex
                                    horizontalAlign='space-between'
                                    verticalAlign='center'
                                    direction={isFirst ? 'row' : 'column'}
                                  >
                                    <Text
                                      className={styles.tdBefore}
                                      weight='bold'
                                    >
                                      {
                                        headerGroups?.[0]?.headers?.[cellIndex]
                                          ?.Header
                                      }
                                    </Text>
                                    {cell.render('Cell')}
                                  </Flex>
                                  {isFirst && (
                                    <div className={styles.iconDropdown}>
                                      <Icon
                                        fill=''
                                        name={
                                          !open
                                            ? 'arrow-simple-down/24'
                                            : 'arrow-simple-up/24'
                                        }
                                        onClick={
                                          isFirst
                                            ? () => {
                                              onClickRow(rowIndex);
                                            }
                                            : null
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              );
                            })
                          }
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </Flex>
            {pagination && (
              <Pagination
                pages={pageCount}
                selectedPage={state.pageIndex}
                setSelectedPage={setControlledPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
              />
            )}
          </>
        ) : (
          <EmptyState>{emptyStateComponent}</EmptyState>
        )}
      </Flex>
      {/* </Styles> */}
    </Container>
  );
};
