import { Flex, Text } from "@talent-garden/react-components";
import React, { FC } from 'react';

const Msg: FC<{ title: string, description: string }> = ({ title = "", description = "" }) => {
  return (
    <Flex direction="column" rowGap={8}>
      <Text weight="bold">{title}</Text>
      <Text size={14}>{description}</Text>
    </Flex>
  );
};

export default Msg;
