/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import styles from './Checkbox.module.css';

type CheckboxProps = {
  checked: boolean;
  label?: string;
  id?: string;
  name?: string;
  setChecked: (value: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = ({
  checked = false,
  label = '',
  id = '',
  name = '',
  setChecked = () => true,
}) => {
  return (
    < div onClick={() => setChecked(!checked)} className={styles.checkbox} >
      <input type="checkbox" id={id} name={name} checked={checked} readOnly />
      <span className={styles.checkmark} />
      <label htmlFor={name}>{label}</label>
    </div >
  );
};

export default Checkbox;
