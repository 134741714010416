import {
  Button,
  Dropdown,
  Flex,
  Icon,
  Separator,
  Text,
} from '@talent-garden/react-components';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import { checkSameMonth, enumerateMonthsBetweenDates } from '../../utils';
import styles from './DropDownMonths.module.css';

type DropDownMonthsProps = {
  onClick: (month: any) => void,
  selected: string,
  selectedMonth: string,
  minDate?: string,
  showMonthsPicker: boolean,
  setShowMonthsPicker: (v: boolean) => void,
}

const DropDownMonths: FC<DropDownMonthsProps> = ({
  onClick,
  selected = '',
  selectedMonth = '',
  minDate = '2021-09-01',
  showMonthsPicker,
  setShowMonthsPicker,
}) => {
  const availableMonths = useMemo(() => {
    return enumerateMonthsBetweenDates(moment(minDate), moment());
  }, []);

  return (
    <Dropdown
      className={styles.dropDownMonths}
      open={showMonthsPicker}
      onOpenChange={(v) => setShowMonthsPicker(v)}
      activator={
        <Button
          dimension='small'
          kind='secondary-light'
          noBorder
          capitalize={false}
        >
          <Flex direction='row' rowGap={8} columnGap={8} verticalAlign='center'>
            <Text size={14} weight="bold">{`${checkSameMonth(selectedMonth) ? 'This month' : 'Month'
              }: ${selected}`}</Text>
            <Icon
              fill='rgba(0, 0, 0, 1)'
              name={
                showMonthsPicker ? 'arrow-simple-up/16' : 'arrow-simple-down/16'
              }
            />
          </Flex>
        </Button>
      }
    >
      {availableMonths?.labels?.map((month, index) => {
        return (
          <Flex key={`${month}`}>
            {index !== 0 ? <Separator /> : null}
            <Text
              className={styles.item}
              onClick={(e) => {
                e.preventDefault();
                onClick(month);
              }}
              data-selected={month?.label === selected}
              size={14}
            >
              {month?.label}
            </Text>
          </Flex>
        );
      })}
    </Dropdown>
  );
};

export default DropDownMonths;
