import React from 'react';
import { Heading, Text, Icon, Flex } from '@talent-garden/react-components';
import Image from './Image';
import genericBanner from './GenericBanner.module.css';

const GenericBanner = ({
  onClose,
  showBanner = true,
  description = '',
  image = '',
  title = '',
}) => {
  return (
    showBanner && (
      <Flex direction='row' className={genericBanner.main}>
        <Flex direction='row' rowGap={32} columnGap={24}>
          {image && (
            <Image
              src={image}
              alt={title}
              width={76}
              height={76}
              className={genericBanner.image}
            />
          )}
          <Flex
            className={genericBanner.texts}
            direction='column'
            rowGap={8}
            columnGap={8}
          >
            <Heading as='h4' className={genericBanner.heading}>
              {title}
            </Heading>
            <Text size={16}>{description}</Text>
          </Flex>
        </Flex>
        <Icon
          name='close/24'
          onClick={onClose}
          className={genericBanner.icon}
        />
      </Flex>
    )
  );
};
export default GenericBanner;
