import React, { FC, ReactNode } from 'react';
import styles from './PageContainer.module.css';

const PageContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <main className={styles.main}>
      <div>{children}</div>
    </main>
  );
};
export default PageContainer;
