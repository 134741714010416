import React, { FC } from 'react';
import { Button, Heading, Text } from '@talent-garden/react-components';
import styles from './ManagerAdmin.module.css';
import { ConnectLogo, Image } from '../commons';

export const ManagerAdmin: FC = () => {
  return (
    <div className={styles.ManagerAdmin}>
      <section className={styles.section} data-logo='true'>
        <div className={styles.card}>
          <ConnectLogo /* customClass={styles.logoMobile}  */ />
        </div>
        <div className={styles.card} />
      </section>

      <section className={styles.section}>
        <div className={styles.card}>
          <Heading as='h1' variant className={styles.title}>
            {'Your team,\nour products.'}
          </Heading>
          <Text size={16} className={styles.description}>
            Manage you employees or other team members into your Team space to
            allow anyone to use your subscription plan.
          </Text>
          <div className={styles.buttons}>
            <Button
              as='button'
              capitalize
              dimension='large'
              kind='primary'
              className={styles.button}
              onClick={() => {
                window.location.replace('/api/auth/login');
              }}
            >
              login
            </Button>
          </div>
        </div>
        <div className={styles.card}>
          <Image
            src='/images/mainImage.png'
            alt='Main with three people'
            width={490}
            height={490}
          />
        </div>
      </section>
    </div>
  );
};
