import React, { FC } from 'react';
import Head from 'next/head';

const Meta: FC<{ title?: string, keywords?: string[], description?: string, noFollow: boolean }> = ({ title, keywords, description, noFollow }) => {
  const isProduction =
    process.env.NODE_ENV === 'production' &&
    process.env.ENVIRONMENT === 'production';

  return (
    <Head>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name="keywords" content={keywords?.join(", ")} />
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <link
        rel="apple-touch-icon"
        href="https://cdn.talentgarden.com/images/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        href="https://cdn.talentgarden.com/images/favicon/favicon.ico"
      />

      <title>{title}</title>
      {noFollow && <meta name="robots" content="noindex" />}
      {/* enable script only for production */}
      {isProduction && (
        <>
          {/* Iubenda */}
          <script
            type="text/javascript"
            src="//cdn.iubenda.com/cs/iubenda_cs.js"
            charSet="UTF-8"
            async
          />
          {/* Global site tag (gtag.js) - Google Analytics  */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <script
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
          <script
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: `
              var _iub = _iub || [];
              _iub.csConfiguration = 
                {"invalidateConsentWithoutLog":true,
                "cookiePolicyInOtherWindow":true,
                "askConsentAtCookiePolicyUpdate":true,
                "preferenceCookie":{"expireAfter":180},
                "consentOnContinuedBrowsing":false,
                "perPurposeConsent":true,"lang":"en",
                "siteId":${process.env.IUBENDA_SITE_ID},
                "floatingPreferencesButtonDisplay":"bottom-left",
                "cookiePolicyId":${process.env.IUBENDA_COOKIE_POLICY_ID},
                "cookiePolicyUrl":"https://talentgarden.org/en/cookie-policy/", 
                "banner":{ "acceptButtonDisplay": true,
                            "customizeButtonDisplay":true,
                            "acceptButtonColor":"#ee7660",
                            "acceptButtonCaptionColor":"white",
                            "customizeButtonColor":"#ee7660",
                            "customizeButtonCaptionColor":"#ffffff",
                            "rejectButtonDisplay":true,
                            "rejectButtonColor":"#ee7660",
                            "rejectButtonCaptionColor":"white",
                            "listPurposes":true,
                            "position":"float-center",
                            "backgroundOverlay":true,
                            "textColor":"black",
                            "backgroundColor":"white",
                            "explicitWithdrawal":true }};`,
            }}
          />
        </>
      )}
      {isProduction && (
        <>
          <meta property="og:site_name" content="Talent Garden" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content="https://connect.talentgarden.com/" />

          <meta
            property="og:image"
            content="https://connect.talentgarden.com/images/social_image.png"
          />

          <meta
            property="og:image:secure_url"
            content="https://connect.talentgarden.com/images/social_image.png"
          />
          <meta property="og:image:width" content="1500" />
          <meta property="og:image:height" content="1000" />
          <meta
            property="article:publisher"
            content="https://www.facebook.com/talentgardenitaly/?brand_redir=237479089626498"
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@TalentGardenen" />
          <meta
            name="twitter:domain"
            content="https://connect.talentgarden.com/"
          />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta
            name="twitter:image"
            content="https://connect.talentgarden.com/images/social_image.png"
          />
        </>
      )}
    </Head>
  );
};

Meta.defaultProps = {
  title: 'Talent Garden | Connect',
  keywords:
    ["talent garden", "platform", "connect", "coworking", "organization", "tag", "talent", "garden"],
  description:
    'Manage you employees or other team members into your Team space to allow anyone to use your subscription plan.',
};

export default Meta;
