import {
  Button,
  Dialog,
  Flex,
  Heading,
  Text,
  TextField
} from "@talent-garden/react-components";
import React, { FC, useState } from "react";
import { User } from '../../types/globals';
import fixedBanner from "./FixedBanner.module.css";

type FixedBannerProps = {
  usersSelected: User[];
  onDeselect: () => void;
  onDelete: () => void;
}

const FixedBanner: FC<FixedBannerProps> = ({ usersSelected = [], onDeselect, onDelete }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [apiCallError] = useState("");
  const [deleteControl, setDeleteControl] = useState("");

  return usersSelected?.length ? (
    <Flex
      className={fixedBanner.main}
      horizontalAlign="space-between"
      direction="column"
      verticalAlign="center"
      rowGap={24}
    >
      {usersSelected?.length === 1 && usersSelected?.[0]?.full_name ? (
        <Heading as="h3">{usersSelected?.[0]?.full_name} selected</Heading>
      ) : (
        <Heading as="h3">{usersSelected?.length} people selected</Heading>
      )}
      <Flex direction="row" columnGap={24}>
        <Dialog
          activator={
            <Button capitalize dimension="large" kind="primary">
              DELETE
            </Button>
          }
          onOpenChange={(open) => {
            setOpenDialog(open);
          }}
          open={openDialog}
          withSeparator={false}
        >
          <Flex
            direction="column"
            columnGap={24}
            rowGap={24}
            horizontalAlign="center"
            className={fixedBanner.deleteDialog}
          >
            <Heading as="h3" textAlign="center">
              Are you sure you want to remove {usersSelected?.length} people
              from your team?
            </Heading>
            <Text as="p" textAlign="center" className={fixedBanner.description}>
              Please notice that users will be not more able to use our
              products.
            </Text>
            <Flex
              direction="row"
              verticalAlign="left"
              className={fixedBanner.confirmDelete}
            >
              <TextField
                dimension="small"
                label="Insert DELETE word to confirm"
                placeholder="DELETE"
                onChange={(text) => {
                  setDeleteControl(text.target.value);
                }}
              />
            </Flex>

            <Flex direction="row" columnGap={24} horizontalAlign="center">
              <Button
                capitalize
                dimension="regular"
                kind="primary"
                // TODO check se la modifica è ok
                // disabled={deleteControl === "DELETE" ? false : true}
                disabled={deleteControl !== "DELETE"}
                onClick={() => {
                  setTimeout(() => {
                    setOpenDialog(false);
                  }, 200);
                  onDelete();
                }}
                fullWidth
              >
                YES, DELETE
              </Button>
              <Button
                capitalize
                dimension="regular"
                kind="secondary"
                onClick={() => {
                  setOpenDialog(false);
                }}
                fullWidth
              >
                NO
              </Button>
              <Text
                as="p"
                textAlign="center"
              >
                {apiCallError?.toString()}
              </Text>
            </Flex>
          </Flex>
        </Dialog>

        <Button
          capitalize
          dimension="large"
          kind="secondary-light-variant"
          onClick={onDeselect}
        >
          DESELECT
        </Button>
      </Flex>
    </Flex>
  ) : null;
};

export default FixedBanner;
