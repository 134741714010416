import { Flex, Heading, Icon, Text } from "@talent-garden/react-components";
import React, { FC, useMemo } from "react";
import styles from "./MostBookingsPerUser.module.css";
import { Image, SkeletonLoader } from "../commons";
import { calculateExactHour } from "../../utils";
import { User } from '../../types/globals';

const Progress: FC<{ width: number }> = ({ width }) => {
  return (
    <Flex className={styles.progress}>
      <Flex className={styles.progressDone} style={{ width }} />
    </Flex>
  );
};

type UserBarProps = {
  user: User;
  totalHours: number;
  userMinutes: number,
  empty: boolean
}

const UserBar: FC<UserBarProps> = ({ user, totalHours, userMinutes, empty }) => {
  const progressWidth = userMinutes / (totalHours / 40);
  const exactHours = !empty && calculateExactHour(userMinutes / 60);
  return (
    <Flex
      className={styles.userBarWrapper}
      direction="row"
      columnGap={24}
      verticalAlign="center"
      horizontalAlign="space-between"
    >
      {!empty && (
        <>
          <Flex direction="row" columnGap={16} verticalAlign="center">
            <Flex className={styles.avatar}>
              <Icon fill="" name="people-fill/24" />
            </Flex>
            <Text size={14}>{user?.full_name || user?.id}</Text>
          </Flex>
          <Flex
            direction="row"
            columnGap={16}
            verticalAlign="center"
            horizontalAlign="space-beetwen"
            className={styles.progressContainer}
          >
            <Progress width={progressWidth} />
            <Text size={14} weight="bold" className={styles.userHours}>
              {`${exactHours} ${exactHours > 1 ? "hours" : "hour"}`}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};

const MostBookingsPerUser = ({ sumHours, users }) => {
  const emptyBlocksLenght = useMemo(() => {
    const maxBlocks = 3;
    if (users?.length && users?.length < maxBlocks) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return maxBlocks - users?.length || 0
    }
    return 0
  }, [users])

  const renderContent = () => {
    if (users) {
      if (users?.length) {
        return <Flex
          direction="column"
          rowGap={16}
          className={styles.usersContainer}
        >
          {[...users, ...Array(emptyBlocksLenght).fill(null).map((_, index) => index)]?.map((user) => {
            return (
              <UserBar
                user={user}
                totalHours={sumHours}
                userMinutes={user?.minutes}
                key={user?.id}
                empty={!Object.keys(user)?.length}
              />
            );
          })}
        </Flex>
      }
      return <Flex
        className={styles.emptyStateUsers}
        direction="column"
        columnGap={8}
        rowGap={8}
        verticalAlign="center"
        horizontalAlign="center"
      >
        <Image
          src="/images/empty_warning.png"
          alt=""
          width={64}
          height={64}
        // layout={'fill'}
        />
        <Text size={11}>No bookings found</Text>
      </Flex >
    }

    return <SkeletonLoader
      width={350}
      height={200}
      marginVertical={20}
      marginHorizontal={0}
    />

  }

  return (
    <Flex
      className={styles.wrapper}
      horizontalAlign="center"
      rowGap={24}
      direction="column"
    >
      <Heading as="h5">Users with the most bookings</Heading>
      {renderContent()}
    </Flex>
  );
};

export default MostBookingsPerUser;
