import moment from 'moment';
import { encode, decode } from 'js-base64';
import CryptoJS from 'crypto-js';

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const truncate = (s, size) => {
  if (typeof s !== 'string') {
    return '';
  }
  if (s?.length <= size) {
    return s;
  }
  return `${s.substring(0, size)}...`;
};

export const formatDay = (date, format = null) => {
  const day = moment(date);
  if (format) {
    return day.format(format);
  }
  if (day.isSame(moment(), 'day')) {
    return `Today, ${day.format('MMMM DD')}, ${day.format('YYYY')}`;
  } else if (day.isSame(moment().add(1, 'day'), 'day')) {
    return `Tomorrow, ${day.format('MMMM DD')}, ${day.format('YYYY')}`;
  } else {
    return `${day.format('dddd')}, ${day.format('MMMM DD')}, ${day.format(
      'YYYY'
    )}`;
  }
};

export const checkSameWeek = (day) => {
  return !!day && moment().isSame(moment(day), 'week');
};

export const checkSameMonth = (day) => {
  return !!day && moment().isSame(moment(day), 'month');
};

export const formatDayMinified = (
  date,
  format = null,
  checkDateAfter = false,
  translateDate = true
) => {
  let day = moment(date);
  if (checkDateAfter && moment(date).isAfter(moment(), 'day')) {
    day = moment();
  }

  if (translateDate) {
    if (day.isSame(moment(), 'day')) {
      return 'today';
    } else if (day.isSame(moment().add(1, 'day'), 'day')) {
      return 'tomorrow';
    }
  }
  if (format) {
    return day.format(format);
  } else {
    return `${day.format('dddd')}`;
  }
};

export const formatHour = (
  date,
  hourFormat,
  extended = false,
  onlyHour = false
) => {
  const day = date && moment(date);
  if (hourFormat === '24') {
    return day?.format('HH:mm');
  }
  if (extended) {
    return day?.format('hh:mm A');
  }
  if (onlyHour) {
    return day?.format('hh A');
  }
  return day?.format('hh:mm');
};

export const roundToNearest = (value, interval) => {
  return Math.round((value + interval / 2) / interval) * interval;
};

export const calculateExactHour = (value) => {
  const decimal = value;
  const remainder = decimal - parseInt(decimal);

  const exactHours = remainder >= 0.5 ? Math.floor(decimal) + 0.5 : Math.floor(decimal);

  // const exactHours =
  //   remainder > 0.5
  //     ? Math.floor(decimal)
  //     : parseInt(decimal) +
  //     (remainder ? roundToNearest(remainder, 50) / 100 : 0);

  return exactHours;
};

export const generateDaysBetweenDates = (
  startDate,
  endDate,
  addBeforeAfter = false
) => {
  let dates = [];
  let currDate = moment(startDate).startOf('day');
  let lastDate = moment(endDate).endOf('day');
  const diff = moment(lastDate).diff(currDate, 'day');

  if (diff < 0) {
    return;
  }

  dates = new Array(diff + 1).fill(null).map((_, index) => {
    if (index === diff) {
      return moment(lastDate);
    }
    return moment(currDate).add(index, 'day');
  });

  if (addBeforeAfter) {
    // add 1day before and 1day after
    dates.unshift(moment(currDate)?.subtract(1, 'day'));
    dates.push(moment(lastDate)?.add(1, 'day'));
  }

  return { dates };
};

export const enumerateMonthsBetweenDates = (startDate, endDate) => {
  let currDate = moment(startDate).startOf('month');
  let lastDate = moment(endDate).endOf('month');
  const diff = moment(lastDate).diff(currDate, 'month');

  const dates = Array(diff + 1).fill(null).map((_, index) => index).map((index) => {
    // if (index + 1 === diff) {
    //   return moment(currDate).add(index, 'month').endOf('month');
    // }
    return moment(currDate).add(index, 'month');
  });
  // dates.push(moment(lastDate));

  const labels = dates.map((date) => {
    return {
      label: moment(date).format('MMMM YYYY'),
      from: moment(date).format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      to: moment(date)
        .add(1, 'month')
        .subtract(1, 'day')
        .endOf('day')
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      key: moment(date).format('MMMYYYY'),
    };
  });
  return { dates, labels };
};

export const getErrorMessage = (message) => {
  switch (true) {
    case message?.includes('already exists'):
    case message?.includes('already exist'):
      return { title: 'Ops, something went wrong', description: message };
    default:
      return { title: 'Ops, something went wrong', description: 'Please try again...' };
  }
}

/* 


dataset dalla chiamata
filtro per date from e to
se devo filtrare per campus filtro per campus altrimenti faccio dataset che comprende tutte gli accessi divisi per giorni
ritorno somma totale di accessi nell'intervallo (count utenti per quel determinato giorno)
ritorno somma totale di accessi per giorno {day, access univoci non somma (count utenti per quel determinato giorno)}
ritorno numero di utenti che hanno fatto almeno un accesso

grafico 1: active member = utenti con almeno un accesso in campus (da segnare)
          prendo settimana corrente e settimana passata e faccio conteggio
          prendo utenti univoci per settimana corrente e settimana passata

        
*/

export const generateAccessDataset = (
  dataset = {},
  filterByCampus = null,
  from: string,
  to: string,
  addBeforeAfter = true
) => {
  let actualDataset = {};

  if (filterByCampus) {
    actualDataset = { ...dataset?.[filterByCampus] };
  } else {
    Object.keys(dataset)?.forEach((campus) => {
      const dataByCampus = dataset[campus];
      Object.keys(dataset[campus])?.forEach((day) => {
        actualDataset[day] = Object.assign(
          actualDataset[day] || {},
          dataByCampus[day]
        );
      });
    });
  }

  const dates = Object.keys(actualDataset)?.filter((date) => {
    return (
      !!date &&
      moment(date).isSameOrAfter(moment(from)) &&
      moment(date).isSameOrBefore(moment(to))
    );
  });

  //ritorno somma totale di accessi nell'intervallo (count utenti per quel determinato giorno)
  const accessTotal = dates
    ?.map((day) => Object.values(actualDataset[day])?.length)
    ?.reduce((sum, actual) => sum + actual, 0);

  //ritorno somma totale di accessi per giorno {day, access univoci non somma (count utenti per quel determinato giorno)}
  const accessTotalByDay = dates?.map((day) => ({
    day,
    count: Object.values(actualDataset[day])?.length,
  }));

  // ritorno numero di utenti che hanno fatto almeno un accesso
  const users = dates?.map((day) => Object.keys(actualDataset[day])).flat();
  // unique users
  let uniqueUsers = users.filter((value, index) => {
    return users.indexOf(value) == index;
  })?.length;

  const { dates: days } = generateDaysBetweenDates(from, to, addBeforeAfter);

  const finalDataset = days?.map((day, index) => {
    return {
      day: day?.format('YYYY-MM-DD'),
      count: actualDataset[day?.format('YYYY-MM-DD')]
        ? Object.values(actualDataset[day?.format('YYYY-MM-DD')])?.length
        : moment(day)?.isAfter(moment())
          ? null
          : 0,
      label:
        addBeforeAfter && (index === 0 || index === days?.length - 1)
          ? ''
          : !!day && moment(day).format('D ddd')?.split(' '),
    };
  });

  return {
    uniqueUsers,
    accessTotalByDay,
    accessTotal,
    finalDataset,
  };
};

export const groupBy = (x, f) =>
  x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});
// f -> should must return string/number because it will be use as key in object

type UsersGrouped = { [key: string]: any[] }

export const generateBookingDataset = (
  dataset = {},
  filterByCampus = null,
  from,
  to,
  usersList = null
) => {
  const bookingFiltered = Object.keys(dataset)
    ?.map((c) => {
      return Object.keys(dataset?.[c])
        .filter((date) => {
          return (
            !!date &&
            moment(date).isSameOrAfter(moment(from), 'day') &&
            moment(date).isSameOrBefore(moment(to), 'day')
          );
        })
        ?.map((day) => {
          return Object.entries(dataset[c][day])?.map(([key, value]) => {
            return { user: key, ...(value as {}), day };
          });
        })
        .flat();
    })
    .flat();

  const usersGrouped = groupBy(bookingFiltered, (v) => v?.user);

  const users = Object.entries(usersGrouped)?.length
    ? Object.entries(usersGrouped as UsersGrouped)
      .map(([key, value]) => {
        return {
          id: key,
          minutes: value?.reduce((sum, v) => (sum += v?.minutes), 0),
          times: value?.reduce((sum, v) => (sum += v?.times), 0),
        };
      })
      ?.map((user) => {
        if (!usersList?.length) {
          return user;
        }
        return {
          ...user,
          ...usersList?.find(
            (u) => u?.id?.toString() === user?.id?.toString()
          ),
        };
      })
      .sort((a, b) => b?.minutes - a?.minutes)
    : [];

  return {
    bookingTotal: bookingFiltered?.reduce((sum, val: any) => (sum += val?.times), 0),
    minutesTotal: bookingFiltered?.reduce(
      (sum, val: any) => (sum += val?.minutes),
      0
    ),
    users,
  };
};
// export const checkTokenSession = async (accessToken, refreshToken) => {
//   const decoded = jwt_decode(accessToken);

//   if (moment(decoded?.exp * 1000).isSameOrBefore(moment().add(3, 'days'))) {
//     let newAccessToken = null;
//     const userCredentials = await checkSession(refreshToken);

//     if (userCredentials) {
//       newAccessToken = String(userCredentials?.accessToken);
//       const idToken = String(userCredentials?.idToken);
//       console.log('[ TOKEN REFRESHED ] userCredentials:', userCredentials);
//       try {
//         await AsyncStorage.setItem('accessToken', newAccessToken);
//         await AsyncStorage.setItem('idToken', idToken);
//       } catch (e) {
//         console.log(e);
//       }
//       updateRequestHeaders(newAccessToken);
//       return newAccessToken;
//     }
//   }
//   return accessToken;
// };

/* 

prende un dataset del mese selezionato
cicla sui campus e date --> creo array con users duplicati
deduplico 


*/

// export const calculateUsersBookingTime = (
//   dataset = {},
//   usersList = [],
//   from,
//   to
// ) => {
//   const tempArr = [];
//   const holder = {};

//   Object.keys(dataset)
//     ?.map((c) => {
//       return Object.keys(dataset[c])
//         ?.filter((date) => {
//           return (
//             !!date &&
//             moment(date).isSameOrAfter(moment(from), 'day') &&
//             moment(date).isSameOrBefore(moment(to), 'day')
//           );
//         })
//         .map((date) => {
//           return dataset[c][date];
//         });
//     })
//     .flat()
//     .map((date) => {
//       Object.keys(date).map((key) => {
//         tempArr.push({ user: key, value: date[key].minutes });
//         return key;
//       });
//     });

//   tempArr.forEach((u) => {
//     if (holder.hasOwnProperty(u.user)) {
//       holder[u.user] = holder[u.user] + u.value;
//     } else {
//       holder[u.user] = u.value;
//     }
//   });

//   const sortableUser = Object.keys(holder)
//     ?.map((u) => ({ id: u, minutes: holder[u] }))
//     .sort((a, b) => b?.minutes - a?.minutes)
//     ?.map((user) => {
//       return {
//         ...user,
//         ...usersList?.find((u) => u?.id?.toString() === user?.id?.toString()),
//       };
//     });

//   const sumValues = Object.values(holder).reduce((a, b) => a + b, 0);

//   return { sumHours: sumValues, users: sortableUser };
// };

export const checkTokenExpiration = async (session) => {
  // check token session expiration
  if (
    !session ||
    (session?.accessTokenExpiresAt &&
      moment(session?.accessTokenExpiresAt * 1000).isSameOrBefore(
        moment().add(1, 'days')
      ))
  ) {
    return true;
  }
  return false;
};

const replaceAllCustom = (str, target, replacement) => {
  return str?.split(target)?.join(replacement);
};

export const createCannyUrl = (userMe, env) => {
  const PrivateKey = env?.privateKey;

  const userData = {
    email: userMe?.email,
    id: userMe?.id,
    name: `${userMe?.first_name} ${userMe?.last_name}`,
    iat: Math.floor(moment().valueOf() / 1000),
    customFields: {
      auth0_id: userMe?.auth0_id,
    },
  };

  const token = generateJWT(userData, PrivateKey);
  const url = `${env?.baseUrl}?companyID=${env?.companyId}&ssoToken=${token}&redirect=https://feedback.talentgarden.com/connect`;
  return url;
};

const generateJWT = (userData, privateKey) => {
  const header = {
    alg: 'HS256',
    typ: 'JWT',
  };

  // encode header
  var stringifiedHeader = JSON.stringify(header);
  var encodedHeader = encode(stringifiedHeader);

  // encode data
  var stringifiedData = JSON.stringify(userData);
  var encodedData = encode(stringifiedData);

  const token = replaceAllCustom(`${encodedHeader}.${encodedData}`, '=', '');

  let signature = CryptoJS.HmacSHA256(token, privateKey);
  var hashInBase64 = CryptoJS.enc.Base64.stringify(signature);
  // signature = base64.encode(JSON.stringify(signature));

  let signedToken = `${token}.${hashInBase64}`;
  signedToken = replaceAllCustom(signedToken, '=', '');
  signedToken = replaceAllCustom(signedToken, '/', '_');
  signedToken = replaceAllCustom(signedToken, '+', '-');

  return signedToken;
};

export const removeAtIndex = (arr, index) => {
  const copy = [...arr];
  copy.splice(index, 1);
  return copy;
};

export const toggle = (arr, item, getValue = (item) => item) => {
  const index = arr.findIndex((i) => getValue(i) === getValue(item));
  if (index === -1) return [...arr, item];
  return removeAtIndex(arr, index);
};
