import {
  Button,
  Text,
} from "@talent-garden/react-components";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { Company } from '../../types/globals';
import styles from "./Sidebar.module.css";
import { SidebarLink } from './SidebarLink';

type SidebarProps = {
  company: Company;
  setSidebarOpen: (open: string) => void;
  open: boolean;
  companyId: string | number;
  showOpenButton: boolean;
}

const Sidebar: FC<SidebarProps> = ({
  company,
  setSidebarOpen,
  open,
  companyId,
  showOpenButton = true,
}) => {
  const router = useRouter();
  const currentRoute = router.pathname;

  return (
    <nav className={styles.nav} data-open={open}>
      {showOpenButton && (
        <Button
          capitalize
          dimension="regular"
          icon={!open ? "arrow-simple-right/16" : "arrow-simple-left/16"}
          kind="primary-dark"
          onClick={() => setSidebarOpen(String(!open))}
          className={styles.sidebarButton}
        />
      )}
      <ul>
        <div className={styles.menuElements}>
          <Text
            size={16}
            textAlign="center"
            weight="bold"
            className={styles.cardDescription}
          >
            {company?.name}
          </Text>

          <SidebarLink
            href={`/organizations/${companyId}`}
            active={currentRoute === `/organizations/[id]`}
            icon="home/24"
            iconActive="home-fill/24"
            text="Home"
            disableTooltip={open}
          />
          <SidebarLink
            href={`/organizations/${companyId}/people`}
            active={currentRoute === `/organizations/[id]/people`}
            icon="profile/24"
            iconActive="profile-fill/24"
            text="People"
            disableTooltip={open}
          />
          <SidebarLink
            href={`/organizations/${companyId}/settings`}
            active={currentRoute === `/organizations/[id]/settings`}
            icon="edit/24"
            iconActive="edit/24"
            text="Settings"
            disableTooltip={open}
          />
        </div>
      </ul>
    </nav>
  );
};

export default Sidebar;
