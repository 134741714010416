import React from 'react';
import { Flex, Icon } from '@talent-garden/react-components';
import { Image } from '../commons';
import style from './ChooseRole.module.css';

const FakeCheckbox = ({ checked }) => {
  return (
    <Flex
      className={style.checkbox}
      data-checked={checked}
      horizontalAlign='center'
      verticalAlign='center'
    >
      {checked && (
        <Icon className={style.icon} fill='burgundy' name='check/16' />
      )}
    </Flex>
  );
};

const ChooseRole = ({ selected, setRole, title, description, image }) => {
  return (
    <Flex
      direction='column'
      columnGap={12}
      rowGap={12}
      onClick={(e) => {
        e.preventDefault();
        setRole();
      }}
      className={style.ChooseRole}
      data-checked={selected}
    >
      <Flex direction='row' horizontalAlign='space-between'>
        <div className={style.imageContainer} data-checked={selected}>
          <Image
            src={image}
            alt={title}
            className={style.image}
            objectFit='contain'
            layout='fill'
            objectPosition='center'
          />
        </div>
        <FakeCheckbox checked={selected} />
      </Flex>
      {title}
      {description}
    </Flex>
  );
};

export default ChooseRole;
