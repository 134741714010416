import React, { FC, useMemo } from 'react';
import {
  Flex,
  Heading,
  Text,
  Separator,
  Icon,
} from "@talent-garden/react-components";
import styles from "./CampusAccessToday.module.css";
import { SkeletonLoader } from '../commons';
import HalfDoughnut from './HalfDoughnut';
import { calculateExactHour } from '../../utils';

type BookingsPieChartProps = {
  description: string,
  title: string,
  countCurrent: number,
  countPrevious: number,
  diff: number,
  nameCurrent: string,
  namePrev: string,
}

export const BookingsPieChart: FC<BookingsPieChartProps> = ({
  description,
  title,
  countCurrent,
  countPrevious,
  diff,
  nameCurrent,
  namePrev,
}) => {
  const data = useMemo(() => {
    return [countPrevious, countCurrent];
  }, [countCurrent, countPrevious]);

  return (
    <Flex
      className={styles.wrapper}
      direction="column"
      rowGap={8}
      columnGap={8}
      horizontalAlign="center"
      verticalAlign="space-between"
    >
      <Heading as="h5" className={styles.entrances}>
        {title}
      </Heading>

      {countCurrent >= 0 && countPrevious >= 0 ? (
        <HalfDoughnut
          dataset={data?.map((d) => Math.ceil(d))}
          colors={["#EC7660", "#723349"]}
        />
      ) : (
        <SkeletonLoader
          width={160}
          height={160}
          marginVertical={20}
          marginHorizontal={0}
        />
      )}
      <Flex
        horizontalAlign="center"
        verticalAlign="center"
        direction="row"
        columnGap={32}
        rowGap={8}
      >
        <Flex
          direction="column"
          columnGap={8}
          rowGap={4}
          verticalAlign="top"
          horizontalAlign="end"
        >
          <Flex
            direction="row"
            rowGap={8}
            columnGap={8}
            horizontalAlign="left"
            verticalAlign="center"
          >
            <div className={styles.dot} data-month="past" />
            <Text size={14}>{namePrev}</Text>
          </Flex>
          <Heading as="h4">
            {calculateExactHour(countPrevious)}{" "}
            <Text as="span" size={16}>
              h
            </Text>
          </Heading>
        </Flex>

        <Flex
          direction="column"
          columnGap={8}
          rowGap={4}
          verticalAlign="top"
          horizontalAlign="end"
        >
          <Flex
            direction="row"
            rowGap={8}
            columnGap={8}
            horizontalAlign="left"
            verticalAlign="center"
          >
            <div className={styles.dot} data-month="current" />
            <Text size={14}>{nameCurrent}</Text>
          </Flex>
          <Heading as="h4">
            {calculateExactHour(countCurrent)}{" "}
            <Text as="span" size={16}>
              h
            </Text>
          </Heading>
        </Flex>
      </Flex>
      <Flex direction="column" rowGap={12} horizontalAlign="center">
        <Separator className={styles.separator} />
        <Flex
          horizontalAlign="center"
          verticalAlign="center"
          direction="row"
          columnGap={8}
          rowGap={8}
        >
          <Icon
            fill={diff >= 0 ? "#73d17d" : "#e16161"}
            name={diff >= 0 ? "arrow-increase/24" : "arrow-decrease/24"}
          />
          <Text size={14}>{description}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
