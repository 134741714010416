import React, { FC } from 'react';
import { Flex, Heading, Text } from '@talent-garden/react-components';
import styles from './SettingsBanner.module.css';
import Image from '../commons/Image';

export const SettingsBanner: FC = () => {
  return (
    <Flex
      direction='column'
      horizontalAlign='center'
      verticalAlign='center'
      className={styles.wrapper}
      rowGap={8}
    >
      <Image
        src='/images/settings-banner.png'
        alt='settings banner'
        className={styles.Image}
        height={93}
        width={93}
      />
      <Heading as='h3'>Heads up!</Heading>
      <Text textAlign='center'>
        This data will be used when you or another team admin purchase a new
        Talent Garden product on Marketplace.
      </Text>
    </Flex>
  );
};

