import { Icon, IconProps, Text } from '@talent-garden/react-components';
import Link from 'next/link';
import React, { FC, ReactNode } from 'react'
import * as Tooltip from '@radix-ui/react-tooltip';
import styles from './Sidebar.module.css'

export type SidebarLinkProps = {
    href: string;
    active?: boolean;
    icon: IconProps['name'];
    iconActive?: IconProps['name'];
    text: string;
    external?: boolean;
    underline?: boolean;
    size?: 11 | 14 | 16;
    disabled?: boolean;
    noPaddingBottom?: boolean;
    disableTooltip?: boolean;
}

const LinkComponent: FC<{ external?: boolean; href: string; children: ReactNode }> = ({ external = false, href, children }) => {
    return external ? <a href={href} target="_blank" rel="noopener noreferrer">{children}</a> : <Link passHref href={href}>{children}</Link>;
}

export const SidebarLink: FC<SidebarLinkProps> = ({ href, active, icon, iconActive, text, external, underline = false, size = 16, disabled = false, noPaddingBottom = false, disableTooltip = true }) => {
    const trigger =
        <LinkComponent external={external} href={href}>
            <li
                data-active={active}
                className={styles.link}
                data-underline={underline}
                data-external={external}
            >
                <Icon
                    className={styles.linkIcon}
                    name={active && iconActive ? iconActive : icon}
                />
                <Text size={size} className={styles.linkLabel} weight={active ? "bold" : "normal"}>{text}</Text>
            </li>
        </LinkComponent>

    return (
        <div className={styles.linkParent} data-disabled={disabled}>
            {!disableTooltip ? <Tooltip.Provider disableHoverableContent>
                <Tooltip.Root delayDuration={500} disableHoverableContent>
                    <Tooltip.Trigger asChild>
                        <div>
                            {trigger}
                        </div>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content side="right" sideOffset={12} avoidCollisions arrowPadding={6} className={styles.tooltipContent}>
                            <Text as="span" size={14}>{text}</Text>
                            <Tooltip.Arrow width={11} height={5} className={styles.tooltipArrow} />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider> : trigger}
        </div>
    )
}
